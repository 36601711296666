<template>
  <a-modal
    v-model:visible="visible"
    destroyOnClose
    centered
    :keyboard="false"
    width="800px"
    :class="['info-dialog', 'info-dialog-temp-' + tempId]"
    :footer="false"
    :bodyStyle="{ padding: 0, background: 'transparent' }"
    @cancel="handleCancel"
  >
    <div class="info-dialog-content">
      <div class="head"></div>
      <div class="content">
        <a-spin :spinning="spinning">
          <div
            class="title"
            style="
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 96%;
            "
            :title="data.stageName"
          >
            {{ data.stageName }}
          </div>
          <div class="vhtml">
            <div
              v-if="data.isLock"
              style="padding: 5px 10px; background: #ebd5d5; margin-bottom: 5px"
            >
              <ExclamationCircleOutlined class="icon" />
              <template v-if="data.order == 0">{{
                $t("map.当前尚未达到学习地图开始学习时间")
              }}</template>
              <template v-else-if="mapData.learnCycle != 1">{{
                $t(
                  "map.学习地图开启了关卡间按顺序学习，完成上一关卡后才能解锁当前关卡"
                )
              }}</template>
              <template v-else>{{
                $t(
                  "map.学习地图开启了关卡间按顺序学习，完成上一关卡且达到上一关的学习期限后才能解锁当前关卡"
                )
              }}</template>
            </div>
            <div class="labels">
              <a-row :gutter="16">
                <a-col :span="12">
                  <p>
                    {{ $t("map.level_state") }}：
                    <!-- 关卡状态： -->
                    <template v-if="data.learnFlag">
                      <template v-if="data.complete == 1">{{
                        $t("CM_Completed")
                      }}</template>
                      <!-- 已完成 -->
                      <template v-else-if="data.complete == 3">{{
                        $t("cm_overdue")
                      }}</template>
                      <!-- 已逾期 -->
                      <template v-else>{{ $t("XB_UnderWay") }}</template>
                      <!-- 进行中 -->
                    </template>
                    <template v-else-if="data.isLock">{{
                      $t("not_unlocked")
                    }}</template>
                    <template v-else>{{ $t("cm_overdue") }}</template>
                    <!-- 未解锁 -->
                  </p>
                </a-col>
                <a-col :span="12">
                  <p>
                    <!-- 学习期限： -->
                    {{ $t("map.Learning_period") }}：
                    <template v-if="mapData.openStageTimeCycle == 1"
                      >{{ data.effective }}
                      <template v-if="data.dateType == 1">{{
                        $t("Pub_Day")
                      }}</template>
                      <!-- 天 -->
                      <template v-else-if="data.dateType == 2">{{
                        $t("study.month")
                      }}</template>
                      <!-- 月 -->
                      <template v-else-if="data.dateType == 3">{{
                        $t("CM_Year")
                      }}</template>
                      <!-- 年 -->
                    </template>
                    <template v-else
                      >{{ mapData.effective }}
                      <template v-if="mapData.dateType == 1">{{
                        $t("Pub_Day")
                      }}</template>
                      <!-- 天 -->
                      <template v-else-if="mapData.dateType == 2">{{
                        $t("study.month")
                      }}</template>
                      <!-- 月 -->
                      <template v-else-if="mapData.dateType == 3">{{
                        $t("CM_Year")
                      }}</template>
                      <!-- 年 -->
                    </template>
                  </p>
                </a-col>
                <a-col :span="12">
                  <p>
                    {{ $t("LB_Course_StudyProgress") }}：
                    <!-- 学习进度： -->
                    <a-tooltip :title="$t('map.Learning_progress_tip')"
                      >{{ data.progress }}%<ExclamationCircleOutlined
                        class="icon"
                    /></a-tooltip>
                  </p>
                </a-col>
                <a-col :span="12" v-if="data.startUnlock">
                  <p>
                    {{ $t("map.learning_cycle") }}：{{
                      dateFormat(data.startUnlock) +
                      " - " +
                      dateFormat(data.endUnlock)
                    }}
                    <!-- "已开启" : "未开启" -->
                  </p>
                </a-col>
                <a-col :span="12">
                  <p>
                    <!-- 过关条件： -->
                    {{ $t("map.pass_condition") }}：<template
                      v-if="data.learnPass"
                    >
                      <!-- 进度 -->
                      {{ $t("CM_LB_Progress") }}
                      {{ data.learnPass }}% </template
                    ><template v-if="data.examNum">
                      {{ data.learnPass ? " & " : ""
                      }}{{ $t("map.pass_nth_exam", [data.examNum]) }}
                      <!-- 场考试通过 -->
                    </template>
                  </p>
                </a-col>
                <a-col :span="12">
                  <p>
                    <!-- 按顺序学： -->
                    {{ $t("map.learn_in_sequence") }}：{{
                      data.learnOrder == 1
                        ? $t("map.learn_order", 1)
                        : $t("map.learn_order", 2)
                    }}
                    <!-- "已开启" : "未开启" -->
                  </p>
                </a-col>
                <a-col :span="12" v-if="data.examAllNum">
                  <p>
                    {{ $t("map.number_exams_passed") }}：{{ data.examPassNum }}
                  </p>
                  <!-- 考试通过场数： -->
                </a-col>
                <a-col :span="12" v-if="data.teacherName">
                  <p>
                    <!-- 帮带导师： -->
                    {{ $t("map.mentor") }}：<OpenData
                      type="userName"
                      :openid="data.teacherName"
                    />
                  </p>
                </a-col>
                <a-col :span="24" v-if="data.intro">
                  <div class="intro">
                    <p
                      :class="{
                        'intro-all': introAll,
                        'intro-overflow': !introAll,
                      }"
                    >
                      {{ $t("map.level_desc") }}：{{ data.intro }}
                      <!-- 关卡说明： -->
                    </p>
                    <span
                      v-if="!introAll"
                      class="intro-ctrl"
                      @click="introAll = true"
                      >{{ $t("CM_All") }}</span
                    >
                    <!-- 全部 -->
                    <span
                      v-if="introAll"
                      class="intro-ctrl"
                      @click="introAll = false"
                      >{{ $t("CM_Retract") }}</span
                    >
                    <!-- 收起 -->
                  </div>
                </a-col>
              </a-row>
            </div>
            <div class="list">
              <a-table
                :dataSource="data.details"
                :columns="columns"
                rowKey="detailId"
                size="middle"
                :bordered="false"
                :pagination="false"
              >
                <template #index="{ index }">
                  {{ index + 1 }}
                </template>
                <template #resourceType="{ text }">
                  {{ getDetailType(text) }}
                </template>
                <template #detailName="{ record }">
                  <template v-if="record.resourceType == 43">
                    <div v-if="record.teach.usePlan == 1">
                      {{ $t("map.help_and_plan") }}
                    </div>
                    <!-- 帮带计划 -->
                    <div v-if="record.teach.useEvaluate == 1">
                      {{ $t("map.help_and_evaluation") }}
                    </div>
                    <!-- 帮带评价 -->
                    <div v-if="record.teach.useSum == 1">
                      {{ $t("map.help_to_summarize") }}
                    </div>
                    <!-- 帮带总结 -->
                  </template>
                  <template v-else>
                    <a-tooltip>
                      <template #title>{{ record.detailName }}</template>
                      {{ record.detailName }}
                    </a-tooltip>
                  </template>
                </template>
                <template #complete="{ record }">
                  <template v-if="record.resourceType == 43">
                    <div style="display: flex; align-items: center">
                      <div>
                        <div v-if="record.teach.usePlan == 1">
                          <span
                            class="complete-status green"
                            v-if="record.teach.teachPlan === 1"
                            >{{ $t("CM_Completed") }}</span
                          >
                          <!-- 已完成 -->
                          <span class="complete-status red" v-else>{{
                            $t("Pub_Uncompleted")
                          }}</span>
                          <!-- 未完成 -->
                        </div>
                        <div v-if="record.teach.useEvaluate == 1">
                          <span
                            class="complete-status green"
                            v-if="record.teach.teachStu === 1"
                            >{{ $t("CM_Completed") }}</span
                          >
                          <!-- 已完成 -->
                          <span class="complete-status red" v-else>{{
                            $t("Pub_Uncompleted")
                          }}</span>
                          <!-- 未完成 -->
                        </div>
                        <div v-if="record.teach.useSum == 1">
                          <template v-if="record.teach.doneType === 1">
                            <span
                              class="complete-status green"
                              v-if="record.teach.teachSum === 1"
                              >{{ $t("CM_Completed") }}</span
                            >
                            <!-- 已完成 -->
                            <span class="complete-status red" v-else>{{
                              $t("Pub_Uncompleted")
                            }}</span>
                            <!-- 未完成 -->
                          </template>
                          <template v-if="record.teach.doneType === 2">
                            <template v-if="record.teach.teachSum === 1">
                              <template v-if="record.teach.reviewResult == 3">
                                <span class="complete-status red">{{
                                  $t("teaching.unqualified")
                                }}</span>
                                <!-- 不合格 -->
                              </template>
                              <template v-else>
                                <span class="complete-status green">{{
                                  $t("CM_Completed")
                                }}</span>
                                <!-- 已完成 -->
                              </template>
                            </template>
                            <span class="complete-status red" v-else>{{
                              $t("Pub_Uncompleted")
                            }}</span>
                            <!-- 未完成 -->
                          </template>
                        </div>
                      </div>
                      <span style="padding-left: 8px"
                        >({{ record.progress }}%)</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    <span
                      class="complete-status gray"
                      v-if="record.complete === 0"
                      >{{ $t("CM_NotLearning") }}({{ record.progress }}%)</span
                    >
                    <!-- 未学习 -->
                    <span
                      class="complete-status green"
                      v-else-if="record.complete === 1"
                    >
                      <a-tooltip placement="top">
                        <template #title>{{
                          dateFormat(record.completeTime)
                        }}</template>
                        {{ $t("CM_Completed") }}({{ record.progress }}%)
                        <!-- 已完成 -->
                      </a-tooltip>
                    </span>
                    <span class="complete-status red" v-else
                      >{{ $t("Pub_Uncompleted") }}({{ record.progress }}%)</span
                    >
                    <!-- 未完成 -->
                  </template>
                </template>
                <template #action="{ record }">
                  <a-button
                    style="min-width: 86px"
                    v-if="record.lockStatus == 0"
                    @click="toStudy(record)"
                    >{{
                      record.complete == 1 || record.resourceType == 43
                        ? $t("cm_view")
                        : $t("LB_Course_StartStudy")
                    }}</a-button
                  >
                  <!-- 查看 -->
                  <a-button v-else-if="record.lockStatus == 1" disabled>{{
                    $t("not_unlocked")
                  }}</a-button>
                  <a-button v-else disabled>{{ $t("cm_overdue") }}</a-button>
                  <!-- "查看" : "开始学习" -->
                </template>
              </a-table>
            </div>
          </div>
        </a-spin>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { useI18n } from "vue-i18n";
import { defineComponent, reactive, toRefs, watch } from "vue";
import { getDetailType } from "@/utils/business";
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";

export default defineComponent({
  components: {
    OpenData,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    tempId: {
      type: Number,
      default: 1,
    },
    mapData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const state = reactive({
      visible: false,
      examAll: 0,
      examPass: 0,
      introAll: false,
      columns: [
        {
          title: $t("map.table.serial"),
          // title: "序号",
          width: 60,
          slots: { customRender: "index" },
        },
        {
          title: $t("map.table.type"),
          // title: "类型",
          dataIndex: "resourceType",
          width: 100,
          slots: { customRender: "resourceType" },
        },
        {
          title: $t("map.table.name"),
          // title: "名称",
          dataIndex: "detailName",
          slots: { customRender: "detailName" },
          ellipsis: true,
        },
        {
          title: $t("map.table.status"),
          // title: "学习状态",
          dataIndex: "complete",
          width: 150,
          slots: { customRender: "complete" },
        },
        {
          title: $t("cm_operate"),
          // title: "操作",
          width: 120,
          slots: { customRender: "action" },
        },
      ],
    });

    watch(
      () => props.isVisible,
      (newVal) => {
        state.visible = newVal;
      }
    );

    const toStudy = (data) => {
      emit("study", data);
    };

    const handleCancel = () => {
      emit("cancal");
    };

    return {
      getDetailType,
      dateFormat,
      ...toRefs(state),
      toStudy,
      handleCancel,
    };
  },
});
</script>
<style lang="less">
.info-dialog {
  .ant-modal-content {
    background: transparent;
    .ant-modal-close-x {
      font-size: 26px;
      color: #c18805;
    }
  }
  &-content {
    position: relative;
    .head {
      background-color: #febd27;
      height: 32px;
      position: absolute;
      top: -16px;
      left: -16px;
      right: -16px;
      border-radius: 16px;
      z-index: -1;
    }
    .content {
      background-color: #fff;
      height: 560px;
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      ::-webkit-scrollbar-button:vertical {
        display: none;
      }
      ::-webkit-scrollbar-track,
      ::-webkit-scrollbar-corner {
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:hover {
        background-color: rgba(193, 136, 5, 0.75);
      }
      ::-webkit-scrollbar-thumb:vertical:active {
        background-color: rgba(193, 136, 5, 0.78);
      }
      .title {
        text-align: center;
        font-size: 30px;
        line-height: 56px;
        margin: 0;
        color: #c18805;
      }
      .vhtml {
        height: 504px;
        padding: 20px;
        overflow-y: scroll;
        .labels {
          margin-bottom: 8px;
          p {
            margin-bottom: 8px;
            font-size: 14px;
            &.relative {
              position: relative;
              .icon {
                position: absolute;
                left: -16px;
                top: 3px;
                color: #999;
              }
            }
          }
          .intro {
            position: relative;
            &-all {
              padding-bottom: 22px;
            }
            &-overflow {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: calc(100% - 38px);
            }
            &-ctrl {
              color: #f7bc45;
              position: absolute;
              right: 0;
              bottom: 0;
              cursor: pointer;
            }
          }
        }
        .complete-status {
          &.red {
            color: red;
          }
          &.green {
            color: green;
          }
        }
      }
      .ant-btn {
        background-color: #fff;
        color: #f7bc45 !important;
        border-color: #f7bc45 !important;
        &:hover {
          color: #f7bc45 !important;
          border-color: #f7bc45 !important;
        }
      }
    }
  }
  &.info-dialog-temp-1 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #9d7621;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #a9853c;
      }
      .intro-ctrl {
        color: #f7bd45;
      }
      .content {
        .title {
          color: #a9853c;
        }
        .ant-btn {
          background-color: #fff;
          color: #f7bd45 !important;
          border-color: #f7bd45 !important;
          &:hover {
            color: #f7bd45 !important;
            border-color: #f7bd45 !important;
          }
        }
      }
    }
  }
  &.info-dialog-temp-2 {
    .ant-modal-content {
      .ant-modal-close-x {
        color: #5b4e7f;
      }
    }
    .info-dialog-content {
      .head {
        background-color: #8f67f7;
      }
      .intro-ctrl {
        color: #8e67f6;
      }
      .content {
        ::-webkit-scrollbar-thumb {
          border-radius: 0;
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:hover {
          background-color: rgba(143, 103, 247, 0.75);
        }
        ::-webkit-scrollbar-thumb:vertical:active {
          background-color: rgba(143, 103, 247, 0.78);
        }
        .title {
          color: #5b4e7f;
        }
        .ant-btn {
          background-color: #fff;
          color: #8e67f6 !important;
          border-color: #8e67f6 !important;
          &:hover {
            color: #8e67f6 !important;
            border-color: #8e67f6 !important;
          }
        }
      }
    }
  }
  .ant-table-tbody > tr > td {
    vertical-align: middle;
  }
}
</style>
